// // src/App.js
// import React, { useState } from 'react';
// import Dashboard from './Dashboard';
// import Login from './Login';
// import 'bootstrap/dist/css/bootstrap.min.css';

// const App = () => {
//   const [isLoggedIn, setIsLoggedIn] = useState(false);
//   const [id, setId] = useState(null)

//   const handleLogin = () => {
//     setIsLoggedIn(true);
//   };

//   return (
//     <div className="App">
//       {/* {isLoggedIn ? <Dashboard /> : <Login onLogin={handleLogin} />} */}
//       <Dashboard id={id}/>
//     </div>
//   );
// };

// export default App;

// src/App.js
import React, { useState, useEffect } from 'react';
import { HashRouter as Router, Route, Routes, useParams } from 'react-router-dom';
import Dashboard from './Dashboard';
import Login from './Login';
import 'bootstrap/dist/css/bootstrap.min.css';

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  const [id, setId] = useState(null);

  const handleLogin = () => {
    setIsLoggedIn(true);
  };

  const IdWrapper = () => {
    const { id } = useParams();
    useEffect(() => {
      setId(id);
    }, [id]);

    return isLoggedIn ? <Dashboard id={id} /> : <Login onLogin={handleLogin} />;
  };

  return (
    <Router>
      <div className="App">
        <Routes>
          {/* Define a route with a dynamic ID */}
          <Route path="/:id" element={<IdWrapper />} />
          {/* Default route when there's no ID */}
          <Route path="/" element={isLoggedIn ? <Dashboard id={id} /> : <Login onLogin={handleLogin} />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
